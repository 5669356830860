export const isProduction = true;
export const googlemapsapikey = 'AIzaSyDT8u2W9mcuJ-uESWb9ZAcd6sd6hkmAMVs';
export const environment = Object.freeze({
  production: true,
  brand: 'bt',

  baseUrl: 'https://services.bodytech.company:8002/api',
  portal: 'https://www.bodytech.com.br/api',

  services: {
    baseUrl: 'https://services.bodytech.company',
    evoBaseUrl: 'https://evo-integracao.w12app.com.br',
  },

  sentry: {
    dsn: 'https://600e9684350c429b8c9b8fff48d24641@sentry.dev.bodytech.company/11',
    enabled: true,
    environment: 'prod',
    release: 'bo-btportal-__SENTRY_RELEASE__',
    attachStacktrace: true,
  },

  recaptchaKey: '',

  s3buckets: {
    images: 'https://images.bodytech.company',
    medias: 'https://medias.bodytech.company',
    terms: 'https://terms.bodytech.company'
  },

  s3Region: 'us-east-1',
  s3Environment: 'prod.bodytech.company'

});
